<template>
<ModalForm
    id="modalFormEditArticle"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- FOLIO -->
    <RowForm :twoColums="false">
        <label class="required fs-6 fw-bold mb-2">Código</label>
        <Field  name="code" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    class="itemControl"
                    v-bind="field"
                    type="text" 
                    placeholder="Código"
                    :validate-event="false"
                    :disabled="isEditing"
                    :model-value="value"
                />
            </el-form-item>
        </Field>
    </RowForm>

    <!-- NOMBRE -->
    <div class="fv-row rowItemControl paddingTop">
        <label class="required fs-6 fw-bold mb-2">Nombre</label>
        <Field  name="name" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    type="text" 
                    placeholder="Nombre"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field> 
    </div>  

    <!-- FAMILIA Y SUBFAMILIA -->
    <RowForm class="paddingTop" :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Familia</label>
            <SelectOption 
                class="itemControl"
                name="familyId"
                :options="comboFamily"
                :disabled="readOnly"
                @changeSelectValue="onChangeFamily"
            />
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Clase</label>
            <SelectOption
                class="itemControl"
                name="subFamilyId"
                :options="comboSubFamily"
                :disabled="readOnly"
            />
        </template>
    </RowForm>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    components:{
        Field,
        ModalForm,
        SelectOption,
        RowForm
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Artículo"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let families = ref([]);
        let subFamilies = ref([]);
        let lines = ref([]);
        let txtName = ref("");
        let currentFamily = "";
        let controller = "Lines";

        const validationSchema = Validations.object().shape({
            code: Validations.string().required().label("code"),
            name: Validations.string().required().label("name"),
            familyId: Validations.string().required().label("familyId"),
            subFamilyId: Validations.string().required().label("subFamilyId")
        });

        let currentLineId = ref("");

        onBeforeMount(async() => {
            await getComboOptions("families", "combo",families);
        });

        watch(currentOpenedItem, async(value) => {
            await onChangeFamily(value.familyId);
        });

        //VARAIBLES COMPUTADAS
        const getCurrentLineId = computed(() => {
            return currentLineId.value;
        });

        const initialValues = computed(() => {
            let article = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                article.code = currentOpenedItem.value.code;
                article.name = currentOpenedItem.value.name;
                article.familyId = currentOpenedItem.value.familyId;
                article.subFamilyId = currentOpenedItem.value.subFamilyId;
            }
            
            return article;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboFamily = computed(() => { return families.value; });
        const comboSubFamily = computed(() => { return subFamilies.value; });

        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                await saveUpdatedItem(values);
            }
            modalForm.value.disabledLoading();
        }

        const saveNewItem = async(item) => {
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        item.familyName = families.value.find(f => f.id == item.familyId).name;
                        item.subFamilyName = subFamilies.value.find(f => f.id == item.subFamilyId).name;

                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    
                    if(props.fnCallBackPersistenceNotifier) {
                        item.familyName = families.value.find(f => f.id == item.familyId).name;
                        item.subFamilyName = subFamilies.value.find(f => f.id == item.subFamilyId).name;
                        // item.lineName = lines.value.find(f => f.id == item.lineId).name;
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const getComboOptions = async(resource, action, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}/${action}`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeFamily = async(id) => {
            
            if(id) {
                currentFamily = id;
                subFamilies.value = [];
                lines.value = [];

                modalForm.value.$refs.form.setFieldValue("subFamilyId", "");

                await getComboOptions("subfamilies" ,"combo", subFamilies, id);
            }
        }

        return {
            editForm,
            modalForm,
            txtName,
            validationSchema,

            //Variables computadas
            initialValues,
            comboFamily,
            comboSubFamily,
            readOnly,
            isEditing,
            getCurrentLineId,

            //Funciones
            handleCrudAction,
            onChangeFamily,
            initDefaults
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>
